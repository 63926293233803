import React from 'react';
import { graphql } from 'gatsby';

import Layout from 'components/Layout';
import ColorLanding from 'components/ColorLanding';

export default function KenraColorLandingPage(props) {
  let {
    data: { contentfulColorLanding },
  } = props;

  return (
    <Layout>
      <ColorLanding page={contentfulColorLanding} />
    </Layout>
  );
}

export const query = graphql`
  query {
    contentfulColorLanding {
      title
      text {
        json
      }
      bottomTitles
      bottomTexts
      bottomImages {
        localFile {
          publicURL
        }
      }
      bottomColors
      bottomButtonTexts
      bottomButtonLinks
      slides {
        ...SlideFields
      }
      videosImage {
        localFile {
          publicURL
        }
      }
      videos {
        ...ContentfulVideoFields
      }
      stylistTools {
        ...ContentfulToolFields
      }
    }
  }
`;
