import React from 'react';
import get from 'lodash.get';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

import HeroSlider from 'components/HeroSlider';
import StylistTools from 'blocks/StylistTools';
import Videos from 'blocks/Videos';
import url from 'helpers/url';

import {
  Spacing,
  SharedStyles,
  Container,
  ImageTextGrid,
} from '@matchbox-mobile/kenra-storybook';

let Color = require('color');

const { StSectionInfo, StTitle, StLinkMore } = SharedStyles;

export default function ColorLanding({ page }) {
  return (
    <>
      <Spacing>
        <HeroSlider slides={page.slides} />
      </Spacing>

      <Spacing>
        <StSectionInfo>
          <Container>
            <StTitle>
              <h2>{page.title}</h2>
            </StTitle>
            {documentToReactComponents(page.text.json)}
            <StLinkMore styleBig>
              <a href={url.colorList}>VIEW ALL PRODUCTS</a>
            </StLinkMore>
          </Container>
        </StSectionInfo>
      </Spacing>

      <Spacing removeSpaceTop>
        <ImageTextGrid
          styleSquare
          rowRevers
          items={page.bottomImages.map((image, index) => {
            let bgColor = get(page, `bottomColors[${index}]`, '#d3d3d3');
            return {
              rowBg: bgColor,
              img: get(image, 'localFile.publicURL'),
              title: get(page, `bottomTitles[${index}]`),
              text: get(page, `bottomTexts[${index}]`),
              linkText: get(page, `bottomButtonTexts[${index}]`),
              linkHref: get(page, `bottomButtonLinks[${index}]`),
              linkStyleWhite: Color(bgColor).isDark(),
              rowColorWhite: Color(bgColor).isDark(),
            };
          })}
        />
      </Spacing>

      <Spacing>
        <Videos
          title="Education Videos"
          videos={page.videos}
          bgUrl={get(page, 'videosImage.localFile.publicURL')}
        />
      </Spacing>

      <Spacing>
        <StylistTools tools={page.stylistTools} />
      </Spacing>
    </>
  );
}
